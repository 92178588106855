import { Box } from "@mui/material";
import { type ReactNode } from "react";

interface Props {
  children: ReactNode;
}

/**
 * This is minimalistic wrapper for a page. It doesn't enforce any particular layout or margins, but replaces IonPage (safe area insets)
 * New design pages have different headers so it also doesn not make sense to hardcode it here
 */
export function PageWrapper(props: Props) {
  const { children } = props;
  return (
    <Box
      sx={{
        height: "100vh",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        /**
         * Padding to accommodate device camera notch
         * See https://fathomtech.io/blog/designing-react-web-applications-for-the-notch/
         */
        paddingTop: "env(safe-area-inset-top)",
        // TODO: Reenable this once we render navbar properly from common root with uniform bottom padding
        // paddingBottom: "env(safe-area-inset-bottom)",
        paddingLeft: "env(safe-area-inset-left)",
        paddingRight: "env(safe-area-inset-right)",
      }}
    >
      {children}
    </Box>
  );
}
