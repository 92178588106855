import { isDefined } from "@clipboard-health/util-ts";
import { useAgentPreferences } from "@src/appV2/Agents/api/useAgentPreferences/useAgentPreferences";
import { type DateRange } from "@src/appV2/lib/Calendar";
import { useOpenShifts } from "@src/appV2/OpenShifts/api/useOpenShifts";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import constate from "constate";
import { addDays, format } from "date-fns";

/**
 * TODO: The reason for extracting this to a context is that it will get user filters (selected calendar dates, shift type, etc)
 * from other context and this is a way to centralize the logic for fetching the data
 */
function useShiftDiscoveryListData() {
  const worker = useDefinedWorker();

  const { filters } = useAgentPreferences();

  const dateRange: DateRange = {
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
  };

  const {
    data: openShiftsData,
    isLoading: openShiftsIsLoading,
    isSuccess: openShiftsIsSuccess,
    isError: openShiftsIsError,
    refetch: refetchOpenShifts,
  } = useOpenShifts(
    {
      dateFilter: {
        start: format(dateRange.startDate, "yyyy-MM-dd"),
        end: format(dateRange.endDate, "yyyy-MM-dd"),
      },
      coordinates: worker.geoLocation?.coordinates ?? [0, 0],
      distance: worker.preference.distance ?? 150,
      qualification: worker.preference.qualification ?? "",
      specialities: {
        hasSedationExperience: worker.specialities?.hasSedationExperience ?? false,
        hasTrayAssemblyExperience: worker.specialities?.hasTrayAssemblyExperience ?? false,
      },
    },
    {
      enabled:
        isDefined(filters?.distance) &&
        isDefined(filters?.license) &&
        worker.geoLocation?.coordinates.length === 2,
    }
  );

  return {
    openShiftsData,
    openShiftsIsLoading,
    openShiftsIsSuccess,
    openShiftsIsError,
    refetchOpenShifts,
  };
}

export const [ShiftDiscoveryListDataProvider, useShiftDiscoveryListDataContext] =
  constate(useShiftDiscoveryListData);
