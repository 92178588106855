import { mergeSxProps } from "@clipboard-health/ui-react";
import { Stack, type StackProps } from "@mui/material";
import { type ReactNode } from "react";

interface HorizontalScrollViewProps extends Omit<StackProps, "children"> {
  children: ReactNode[];
  stretch?: boolean;
}

export function HorizontalScrollView(props: HorizontalScrollViewProps) {
  const { children, sx, stretch = false, ...restProps } = props;

  return (
    <Stack
      direction="row"
      spacing={3}
      sx={mergeSxProps(
        {
          overflowX: "auto",
          scrollbarWidth: "none",

          "&:empty": {
            display: "none",
          },

          "& > *": {
            flexShrink: 0,
            width: stretch ? "90%" : undefined,
            maxWidth: "90%",
          },

          "& > :only-child": {
            width: stretch ? "100%" : undefined,
            maxWidth: "100%",
          },
        },
        sx
      )}
      {...restProps}
    >
      {children}
    </Stack>
  );
}
