import { isDefined } from "@clipboard-health/util-ts";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";

import { type ShiftFormattedPayBreakdown, type ShiftWithType } from "./types";

export function getFormattedShiftPayBreakdown(
  offer: ShiftWithType["offer"],
  finalPay?: number,
  originalAmount?: ShiftWithType["originalAmount"]
): ShiftFormattedPayBreakdown | undefined {
  if (!isDefined(offer) || !isDefined(originalAmount)) {
    return undefined;
  }

  return {
    totalPay: formatDollarsAsUsd(originalAmount),
    hourlyPay: formatDollarsAsUsd(offer.pay.value ?? finalPay),
  };
}
