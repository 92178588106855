import { mergeSxProps } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack, type SxProps, type Theme } from "@mui/material";

import { IconButton } from "./IconButton";

interface DialogFooterProps {
  orientation: "horizontal" | "vertical";
  children?: React.ReactNode;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps<Theme>;
}

export function DialogFooter(props: DialogFooterProps) {
  const { children, onClose, orientation = "horizontal", sx } = props;

  const isVertical = orientation === "vertical";

  return (
    <Stack
      direction={isVertical ? "column" : "row"}
      alignItems="center"
      justifyContent={isDefined(children) ? "space-between" : "center"}
      gap={4}
      sx={mergeSxProps(
        {
          px: 5,
          py: 7,
        },
        sx
      )}
    >
      {isVertical && children}

      {isDefined(onClose) && (
        <IconButton
          size="large"
          variant="secondary"
          iconType="close"
          onClick={(event) => {
            event.stopPropagation();
            onClose(event);
          }}
        />
      )}

      {!isVertical && children}
    </Stack>
  );
}
