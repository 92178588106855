import { IconButton as MuiIconButton, type Theme } from "@mui/material";

import { CbhIcon, type IconType } from "./CbhIcon";

export const ICON_BUTTON_SIZES = ["xSmall", "small", "medium", "large"] as const;

export type IconButtonSize = (typeof ICON_BUTTON_SIZES)[number];

export type IconButtonVariant = "primary" | "secondary";

const iconSizeByButtonSize = {
  xSmall: "xSmall",
  small: "small",
  medium: "medium",
  large: "medium",
} as const;

interface IconButtonProps {
  variant: IconButtonVariant;
  iconType: IconType;
  size?: IconButtonSize;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const getStyleForVariant = (variant: IconButtonVariant, theme: Theme) => {
  if (variant === "primary") {
    return {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,

      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },

      "&.Mui-disabled": {
        backgroundColor: theme.palette.disabled?.primary,
        color: theme.palette.disabled?.primary,
      },
    };
  }

  return {
    backgroundColor: theme.palette.background?.tertiary,
    boxShadow: theme.shadows[2],
    border: `1px solid ${String(theme.palette.border?.primary)}`,

    color: theme.palette.primary.main,

    "&:hover": {
      boxShadow: "none",
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.background?.tertiary,
    },

    "&.Mui-disabled": {
      color: theme.palette.disabled?.primary,
      border: `1px solid ${String(theme.palette.disabled?.primary)}`,
      backgroundColor: theme.palette.background?.tertiary,
    },
  };
};

export function IconButton(props: IconButtonProps) {
  const { variant, iconType, size = "medium", onClick, disabled } = props;

  return (
    <MuiIconButton
      color={variant}
      disabled={disabled}
      sx={(theme) => ({
        ...getStyleForVariant(variant, theme),
        width: String(theme?.buttonSize?.[size]),
        height: String(theme?.buttonSize?.[size]),
      })}
      onClick={onClick}
    >
      <CbhIcon type={iconType} size={iconSizeByButtonSize[size]} />
    </MuiIconButton>
  );
}
