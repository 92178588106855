import { Box, Stack } from "@mui/material";
import { Fragment, type ReactNode } from "react";

interface DotSeparatedListProps {
  children: ReactNode[];
}

export function DotSeparatedList(props: DotSeparatedListProps) {
  const { children } = props;

  return (
    <Stack direction="row" alignItems="center" spacing={2} {...props}>
      {children.map((child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`dot-element-${index}`}>
          {child}
          {index < children.length - 1 && (
            <Box
              sx={(theme) => ({
                width: "0.125rem",
                height: "0.125rem",
                borderRadius: "50%",
                backgroundColor: theme.palette.text.primary,
              })}
            />
          )}
        </Fragment>
      ))}
    </Stack>
  );
}
