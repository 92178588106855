import { isDefined } from "@clipboard-health/util-ts";
import { useAgentPreferences } from "@src/appV2/Agents/api/useAgentPreferences";
import { type DateRange } from "@src/appV2/lib";
import { useAgentShifts } from "@src/appV2/OpenShifts/api/useAgentShifts";
import { useOpenShiftCount } from "@src/appV2/OpenShifts/api/useOpenShiftCount";
import { groupShiftsByDateAndPeriod } from "@src/appV2/OpenShifts/utils/groupShiftsByDateAndPeriod";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import constate from "constate";
import { addMonths, endOfMonth } from "date-fns";
import { useState } from "react";

const DEFAULT_DISTANCE_IN_MILES = 150;

const useShiftDiscoveryCalendarData = () => {
  const worker = useDefinedWorker();

  const { filters } = useAgentPreferences();

  // TODO: When we start from list view, we need to change dates here
  const [dateRange, setDateRange] = useState<DateRange>(() => {
    const today = new Date();
    const endDate = endOfMonth(addMonths(today, 2));

    return {
      startDate: today,
      endDate,
    };
  });

  const {
    data: workerShiftsData,
    isSuccess: workerShiftsIsSuccess,
    isLoading: workerShiftsIsLoading,
    refetch: refetchWorkerShifts,
  } = useAgentShifts({
    startDate: dateRange?.startDate,
    endDate: dateRange?.endDate,
    groupByDate: false,
    tmz: worker.tmz ?? "",
  });

  const workerShiftsByDate = workerShiftsIsSuccess
    ? groupShiftsByDateAndPeriod(workerShiftsData.agentShifts ?? [], worker.tmz)
    : {};

  const {
    data: openShiftsCountData,
    isLoading: openShiftsCountIsLoading,
    refetch: refetchOpenShiftsCount,
  } = useOpenShiftCount(
    {
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
      qualification: filters?.license,
      coordinates: worker.geoLocation?.coordinates,
      specialities: {
        hasSedationExperience: worker.specialities?.hasSedationExperience ?? false,
        hasTrayAssemblyExperience: worker.specialities?.hasTrayAssemblyExperience ?? false,
      },
      distance: filters?.distance ?? DEFAULT_DISTANCE_IN_MILES,
      tmz: worker.tmz ?? "",
      isAgent: true,
    },
    {
      enabled: isDefined(worker.tmz) && isDefined(filters),
    }
  );

  const refetch = () => {
    void refetchWorkerShifts();
    void refetchOpenShiftsCount();
  };

  return {
    dateRange,
    setDateRange,
    workerShiftsByDate,
    workerShiftsIsLoading,
    openShiftsCountData,
    openShiftsCountIsLoading,
    refetch,
  };
};

export const [ShiftDiscoveryCalendarDataProvider, useShiftDiscoveryCalendarDataContext] = constate(
  useShiftDiscoveryCalendarData
);
