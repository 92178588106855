/* eslint-disable import/max-dependencies */
import { isDefined } from "@clipboard-health/util-ts";
import ArrowDown from "@src/appV2/lib/assets/icons/arrow_down.svg";
import ArrowLeft from "@src/appV2/lib/assets/icons/arrow_left.svg";
import ArrowRight from "@src/appV2/lib/assets/icons/arrow_right.svg";
import ArrowUp from "@src/appV2/lib/assets/icons/arrow_up.svg";
import BoltFilled from "@src/appV2/lib/assets/icons/bolt_filled.svg";
import Break from "@src/appV2/lib/assets/icons/break.svg";
import Building from "@src/appV2/lib/assets/icons/building.svg";
import CalendarClock from "@src/appV2/lib/assets/icons/calendar_clock.svg";
import CalendarNavigation from "@src/appV2/lib/assets/icons/calendar_navigation.svg";
import CalendarRemove from "@src/appV2/lib/assets/icons/calendar_remove.svg";
import Car from "@src/appV2/lib/assets/icons/car.svg";
import Chat from "@src/appV2/lib/assets/icons/chat.svg";
import Checkmark from "@src/appV2/lib/assets/icons/checkmark.svg";
import ChevronDown from "@src/appV2/lib/assets/icons/chevron_down.svg";
import ChevronLeft from "@src/appV2/lib/assets/icons/chevron_left.svg";
import ChevronRight from "@src/appV2/lib/assets/icons/chevron_right.svg";
import ChevronUp from "@src/appV2/lib/assets/icons/chevron_up.svg";
import Clipboard from "@src/appV2/lib/assets/icons/clipboard.svg";
import Clock from "@src/appV2/lib/assets/icons/clock.svg";
import ClockHistory from "@src/appV2/lib/assets/icons/clock_history.svg";
import Close from "@src/appV2/lib/assets/icons/close.svg";
import Compass from "@src/appV2/lib/assets/icons/compass.svg";
import DateClock from "@src/appV2/lib/assets/icons/date_clock.svg";
import Document from "@src/appV2/lib/assets/icons/document.svg";
import DressCode from "@src/appV2/lib/assets/icons/dress_code.svg";
import Exit from "@src/appV2/lib/assets/icons/exit.svg";
import ExtraPay from "@src/appV2/lib/assets/icons/extra_pay.svg";
import Eye from "@src/appV2/lib/assets/icons/eye.svg";
import Info from "@src/appV2/lib/assets/icons/info.svg";
import Invite from "@src/appV2/lib/assets/icons/invite.svg";
import LikeFilled from "@src/appV2/lib/assets/icons/like_filled.svg";
import LikeOutlined from "@src/appV2/lib/assets/icons/like_outlined.svg";
import MapPin from "@src/appV2/lib/assets/icons/map_pin.svg";
import MoonFilled from "@src/appV2/lib/assets/icons/moon_filled.svg";
import MoonOutlined from "@src/appV2/lib/assets/icons/moon_outlined.svg";
import New from "@src/appV2/lib/assets/icons/new.svg";
import Passkeys from "@src/appV2/lib/assets/icons/passkeys.svg";
import Patients from "@src/appV2/lib/assets/icons/patients.svg";
import Phone from "@src/appV2/lib/assets/icons/phone.svg";
import Plane from "@src/appV2/lib/assets/icons/plane.svg";
import QrCode from "@src/appV2/lib/assets/icons/qr_code.svg";
import Remove from "@src/appV2/lib/assets/icons/remove.svg";
import Search from "@src/appV2/lib/assets/icons/search.svg";
import SliderSettings from "@src/appV2/lib/assets/icons/slider_settings.svg";
import Software from "@src/appV2/lib/assets/icons/software.svg";
import Star from "@src/appV2/lib/assets/icons/star.svg";
import StarFilled from "@src/appV2/lib/assets/icons/star_filled.svg";
import StopWatch from "@src/appV2/lib/assets/icons/stop watch.svg";
import Suitcase from "@src/appV2/lib/assets/icons/suitcase.svg";
import SunFilled from "@src/appV2/lib/assets/icons/sun_filled.svg";
import SunOutlined from "@src/appV2/lib/assets/icons/sun_outlined.svg";
import SunsetFilled from "@src/appV2/lib/assets/icons/sunset_filled.svg";
import SunsetOutlined from "@src/appV2/lib/assets/icons/sunset_outlined.svg";
import Supplies from "@src/appV2/lib/assets/icons/supplies.svg";
import TextBubble from "@src/appV2/lib/assets/icons/text_bubble.svg";
import TimeFlies from "@src/appV2/lib/assets/icons/time_flies.svg";
import User from "@src/appV2/lib/assets/icons/user.svg";
import UserMini from "@src/appV2/lib/assets/icons/user_mini.svg";
import type { SVGProps } from "react";

import { APP_V2_APP_EVENTS } from "../../lib/analytics/events";
import { useLogEffect } from "../../lib/analytics/useLogEffect";
import {
  ICON_SIZE_EXTRA_LARGE,
  ICON_SIZE_EXTRA_SMALL,
  ICON_SIZE_LARGE,
  ICON_SIZE_MEDIUM,
  ICON_SIZE_SMALL,
} from "../theming/components";

export type IconSize = "xSmall" | "small" | "medium" | "large" | "xLarge";

const sizeMap = {
  xSmall: ICON_SIZE_EXTRA_SMALL,
  small: ICON_SIZE_SMALL,
  medium: ICON_SIZE_MEDIUM,
  large: ICON_SIZE_LARGE,
  xLarge: ICON_SIZE_EXTRA_LARGE,
} as const;

export const ICON_TYPES = [
  "arrow-down",
  "arrow-left",
  "arrow-right",
  "arrow-up",
  "bolt-filled",
  "break",
  "building",
  "calendar-clock",
  "calendar-navigation",
  "calendar-remove",
  "car",
  "chat",
  "checkmark",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "clipboard",
  "clock",
  "clock-history",
  "close",
  "compass",
  "date-clock",
  "document",
  "dress-code",
  "exit",
  "extra-pay",
  "eye",
  "info",
  "invite",
  "like-filled",
  "like-outlined",
  "map-pin",
  "moon-filled",
  "moon-outlined",
  "new",
  "passkeys",
  "patients",
  "phone",
  "plane",
  "qr-code",
  "remove",
  "search",
  "slider-settings",
  "software",
  "star",
  "star-filled",
  "stop-watch",
  "suitcase",
  "sun-filled",
  "sun-outlined",
  "sunset-filled",
  "sunset-outlined",
  "supplies",
  "text-bubble",
  "time-flies",
  "user-mini",
  "user",
] as const;

export type IconType = (typeof ICON_TYPES)[number];

const iconMap: Record<IconType, React.FunctionComponent<SVGProps<SVGSVGElement>>> = {
  "arrow-down": ArrowDown,
  "arrow-left": ArrowLeft,
  "arrow-right": ArrowRight,
  "arrow-up": ArrowUp,
  "bolt-filled": BoltFilled,
  break: Break,
  building: Building,
  "calendar-clock": CalendarClock,
  "calendar-navigation": CalendarNavigation,
  "calendar-remove": CalendarRemove,
  car: Car,
  chat: Chat,
  checkmark: Checkmark,
  "chevron-down": ChevronDown,
  "chevron-left": ChevronLeft,
  "chevron-right": ChevronRight,
  "chevron-up": ChevronUp,
  clipboard: Clipboard,
  clock: Clock,
  "clock-history": ClockHistory,
  close: Close,
  compass: Compass,
  "date-clock": DateClock,
  document: Document,
  "dress-code": DressCode,
  exit: Exit,
  "extra-pay": ExtraPay,
  eye: Eye,
  info: Info,
  invite: Invite,
  "like-filled": LikeFilled,
  "like-outlined": LikeOutlined,
  "map-pin": MapPin,
  "moon-filled": MoonFilled,
  "moon-outlined": MoonOutlined,
  new: New,
  passkeys: Passkeys,
  patients: Patients,
  phone: Phone,
  plane: Plane,
  "qr-code": QrCode,
  remove: Remove,
  search: Search,
  "slider-settings": SliderSettings,
  software: Software,
  star: Star,
  "star-filled": StarFilled,
  "stop-watch": StopWatch,
  suitcase: Suitcase,
  "sun-filled": SunFilled,
  "sun-outlined": SunOutlined,
  "sunset-filled": SunsetFilled,
  "sunset-outlined": SunsetOutlined,
  supplies: Supplies,
  "text-bubble": TextBubble,
  "time-flies": TimeFlies,
  "user-mini": UserMini,
  user: User,
} as const;

export interface CbhIconProps extends SVGProps<SVGSVGElement> {
  type: IconType;
  size?: IconSize;
}

export function CbhIcon(props: CbhIconProps) {
  const { type, size = "medium", ...restProps } = props;

  const IconComponent = iconMap[type];

  useLogEffect(
    APP_V2_APP_EVENTS.NON_EXISTING_ICON_REQUESTED,
    {
      type,
    },
    {
      enabled: !isDefined(IconComponent),
    }
  );

  if (!isDefined(IconComponent)) {
    return null;
  }

  return <IconComponent {...restProps} width={sizeMap[size]} height={sizeMap[size]} />;
}
/* eslint-enable import/max-dependencies */
