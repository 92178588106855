import { Card as MuiCard, type CardProps as MuiCardProps } from "@mui/material";

import { type Elevation } from "../theming/common";

export interface CardProps extends MuiCardProps {
  variant?: "primary" | "secondary" | "tertiary";
  outlined?: boolean;
  elevation?: Elevation;
}

export function Card(props: CardProps) {
  const { variant = "tertiary", outlined = false, elevation = 0, ...restProps } = props;

  return <MuiCard {...restProps} variant={variant} elevation={elevation} outlined={outlined} />;
}
